import InstagramStories from "../pages/Social/InstagramStories";
import YouTubeCommunity from "../pages/Social/YouTubeCommunity";
import TwitterLivestream from "../pages/Livestream/Twitter";
import YouTubeThumbnailLivestream from "../pages/Livestream/YouTubeThumbnail";
import YouTubeFirstSlideLivestream from "../pages/Livestream/YouTubeFirstSlide";
import FacebookLivestream from "../pages/Livestream/Facebook";
import LinkedInLivestream from "../pages/Livestream/LinkedIn";
import Twitter from "../pages/Social/Twitter";
import Facebook from "../pages/Social/Facebook";
import LinkedIn from "../pages/Social/LinkedIn";
import Email01 from "../pages/Email/Email01";
import Email02 from "../pages/Email/Email02";
import Email03 from "../pages/Email/Email03";
import Email04 from "../pages/Email/Email04";
import BlogFeatured from "../pages/Social/BlogFeatured";
import BlogSocialShare from "../pages/Social/BlogSocialShare";
import BlogSocialShareInternal from "../pages/Internal/BlogSocialShare";
import YouTubeCommunityLivestream from "../pages/Livestream/YouTubeCommunity";
import InstagramStoriesLivestream from "../pages/Livestream/InstagramStories";
import InstagramCountdownLivestream from "../pages/Livestream/InstagramCountdown";
import BlogFeaturedLivestream from "../pages/Livestream/BlogFeatured";
import BlogSocialShareLivestream from "../pages/Livestream/BlogSocialShare";
import EmailBannerLivestream from "../pages/Livestream/EmailBanner";
import EmailPhotoLivestream from "../pages/Livestream/EmailPhoto";
import StreamYardLivestream from "../pages/Livestream/StreamYard";

export const templates =
  {
    social: [
      {
        id: "Twitter",
        label: "Twitter",
        dimensions: "1200x675",
        zoom: 2,
        getComponent() {
          return <Twitter id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "Facebook",
        label: "Facebook",
        dimensions: "1200x675",
        zoom: 2,
        getComponent() {
          return <Facebook id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "LinkedIn",
        label: "LinkedIn",
        dimensions: "1200x627",
        zoom: 2,
        getComponent() {
          return <LinkedIn id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "YouTubeCommunity",
        label: "YouTube Community",
        dimensions: "1080x1080",
        zoom: 3,
        getComponent() { return <YouTubeCommunity id={this.id} zoom={this.zoom}/>},
      },

      {
        id: "InstagramStories",
        label: "Instagram Stories",
        dimensions: "1080x1920",
        zoom: 3,
        getComponent() {
          return <InstagramStories id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "BlogFeatured",
        label: "Blog Featured",
        dimensions: "1280x720@2x",
        zoom: 3,
        getComponent() { return <BlogFeatured id={this.id} zoom={this.zoom}/>},
      },
      {
        id: "BlogSocialShare",
        label: "Blog Social Share",
        dimensions: "1280x720@2x",
        zoom: 3,
        getComponent() { return <BlogSocialShare id={this.id} zoom={this.zoom}/>},
      },
    ],
    livestream: [
      {
        id: "YouTubeFirstSlideLivestream",
        label: "YouTube First Slide",
        dimensions: "1920x1080",
        zoom: 2,
        getComponent() {
          return <YouTubeFirstSlideLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "StreamYardLivestream",
        label: "Stream Yard Overlay",
        dimensions: "1920x1080",
        zoom: 2,
        getComponent() {
          return <StreamYardLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "YouTubeThumbnailLivestream",
        label: "YouTube Thumbnail",
        dimensions: "1280x720",
        zoom: 2,
        getComponent() {
          return <YouTubeThumbnailLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "TwitterLivestream",
        label: "Twitter",
        dimensions: "1200x675",
        zoom: 2,
        getComponent() {
          return <TwitterLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "FacebookLivestream",
        label: "Facebook",
        dimensions: "1200x630",
        zoom: 2,
        getComponent() {
          return <FacebookLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "LinkedInLivestream",
        label: "LinkedIn",
        dimensions: "1200x627",
        zoom: 2,
        getComponent() {
          return <LinkedInLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "YouTubeCommunityLivestream",
        label: "YouTube Community",
        dimensions: "1080x1080",
        zoom: 3,
        getComponent() {
          return <YouTubeCommunityLivestream id={this.id} zoom={this.zoom}/>
        },
      },
      {
        id: "InstagramStoriesLivestream",
        label: "Instagram Stories",
        dimensions: "1080x1920",
        zoom: 3,
        getComponent() {
          return <InstagramStoriesLivestream id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "InstagramCountdownLivestream",
        label: "Instagram Countdown",
        dimensions: "1080x1920",
        zoom: 3,
        getComponent() {
          return <InstagramCountdownLivestream id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "BlogFeaturedLivestream",
        label: "Blog Featured",
        dimensions: "1280x720@2x",
        zoom: 4,
        getComponent() {
          return <BlogFeaturedLivestream id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "BlogSocialShareLivestream",
        label: "Blog Social Share",
        dimensions: "1280x720@2x",
        zoom: 4,
        getComponent() {
          return <BlogSocialShareLivestream id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "EmailPhotoLivestream",
        label: "Email Photo",
        dimensions: "650x144@2x",
        zoom: 2,
        getComponent() {
          return <EmailPhotoLivestream id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "EmailBannerLivestream",
        label: "Email Banner",
        dimensions: "650x325@2x",
        zoom: 2,
        getComponent() {
          return <EmailBannerLivestream id={this.id} zoom={this.zoom}/>
        },
      },
    ],
    internal: [
      {
        id: "BlogSocialShareInternal",
        label: "Blog Social Share",
        dimensions: "1280x720@2x",
        zoom: 3,
        getComponent() {
          return <BlogSocialShareInternal id={this.id} zoom={this.zoom}/>
        },
      },
    ],
    email: [
      {
        id: "Email01",
        label: "Email",
        dimensions: "650x96@2x",
        zoom: 2,
        getComponent() {
          return <Email01 id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "Email02",
        label: "Email",
        dimensions: "650x200@2x",
        zoom: 2,
        getComponent() {
          return <Email02 id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "Email03",
        label: "Email",
        dimensions: "650x325@2x",
        zoom: 2,
        getComponent() {
          return <Email03 id={this.id} zoom={this.zoom}/>
        },
      },

      {
        id: "Email04",
        label: "Email",
        dimensions: "650x325@2x",
        zoom: 2,
        getComponent() {
          return <Email04 id={this.id} zoom={this.zoom}/>
        },
      },

    ],
  }
