import React, { useContext, useState, createContext, useCallback } from "react";


export const Images = createContext(
  {
    images: {},
    editImages: () => {},
  });


export const ImagesContextProvider = ({ children }) => {

  const [ images, setImages ] = useState({});
  const [ customBg, setCustomBg ] = useState("");
  const [ bgUrl, setBgUrl ] = useState("");
  const [ bgUrl2x, setBgUrl2x ] = useState("");
  const [ bgUrlMini, setBgUrlMini ] = useState("");
  const [ allBgUrls, setAllBgUrls ] = useState([]);
  const [ bgHashes, setBgHashes ] = useState({});
  const [ bgScale, setBgScale ] = useState({});
  const [ bgX, setBgX ] = useState({});
  const [ bgY, setBgY ] = useState({});
  const [ bgData, setBgData ] = useState({});
  const [ uploadScale, setUploadScale ] = useState({});
  const [ uploadX, setUploadX ] = useState({});
  const [ uploadY, setUploadY ] = useState({});
  const [ renderKey, setRenderKey ] = useState(0);
  const [ productHash, setProductHash ] = useState("");


  const editProductHash = value => {
    setProductHash(value)
  }

  const editAllBgUrls = value => {
    setAllBgUrls(value)
  }

  const editBgHashes = value => {
    setBgHashes(value)
  }
  const editBgData = value => {
    setBgData(value)
  }
  const removeImages = () => {
    setImages({});
  }


  const editBgUrl2x = value => {
    setBgUrl2x(value)
  }

  const editBgUrlMini = value => {
    setBgUrlMini(value)
  }

  const editBgUrl = value => {
    setBgUrl(value)
  }

  const loadImages = images => {
    setImages(images);
  }

  const editCustomBg = value => {
    setCustomBg(value)
  }
  const editTransform = (x,y,s) => {
    setBgX(x)
    setBgY(y)
    setBgScale(s)
  }

  const editUploadTransform = (x,y,s) => {
    setUploadX(x)
    setUploadY(y)
    setUploadScale(s)
  }

  const rerenderBg = () => {
    setRenderKey((prev) => {
      return prev + 1
    })
  }


  const editUploadY = (id, value) => {
    setUploadY((curY) => {
      const nextY = {...curY};
      if (!nextY[id]) { nextY[id] = {}; }
      nextY[id] = {...nextY[id]};
      nextY[id] = value;
      return nextY;
    });
  };


  const editUploadX = (id, value) => {
    setUploadX((curX) => {
      const nextX = {...curX};
      if (!nextX[id]) { nextX[id] = {}; }
      nextX[id] = {...nextX[id]};
      nextX[id] = value;
      return nextX;
    });
  };

  const editUploadScale = (id, value) => {
    setUploadScale((curScale) => {
      const nextScale = {...curScale};
      if (!nextScale[id]) { nextScale[id] = {}; }
      nextScale[id] = {...nextScale[id]};
      nextScale[id] = value;
      return nextScale;
    });
  };

  const editBgY = (id, value) => {
    setBgY((curY) => {
      const nextY = {...curY};
      if (!nextY[id]) { nextY[id] = {}; }
      nextY[id] = {...nextY[id]};
      nextY[id] = value;
      return nextY;
    });
  };


  const editBgX = (id, value) => {
    setBgX((curX) => {
      const nextX = {...curX};
      if (!nextX[id]) { nextX[id] = {}; }
      nextX[id] = {...nextX[id]};
      nextX[id] = value;
      return nextX;
    });
  };

  const editBgScale = (id, value) => {
      setBgScale((curScale) => {
        const nextScale = {...curScale};
        if (!nextScale[id]) { nextScale[id] = {}; }
        nextScale[id] = {...nextScale[id]};
        nextScale[id] = value;
        return nextScale;
      });
    };

  const editImages = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setImages((curImages) => {
      const nextImages = {...curImages};
      if (!nextImages[currentId]) { nextImages[currentId] = {}; }
      // nextImages[currentId] = {...nextImages[id]};
      nextImages[currentId] = value;
      return nextImages;
    });
  }

  return (
    <Images.Provider value={{
      images,
      bgScale,
      bgX,
      bgY,
      renderKey,
      customBg,
      uploadScale,
      uploadX,
      uploadY,
      bgUrl,
      bgUrl2x,
      bgHashes,
      bgData,
      allBgUrls,
      bgUrlMini,
      productHash,
      editBgHashes,
      editProductHash,
      editBgUrl,
      loadImages,
      removeImages,
      editCustomBg,
      rerenderBg,
      editImages,
      editTransform,
      editBgScale,
      editBgY,
      editBgX,
      editUploadTransform,
      editUploadScale,
      editUploadY,
      editUploadX,
      editBgData,
      editAllBgUrls,
      editBgUrl2x,
      editBgUrlMini,
    }}>
      { children }
    </Images.Provider>
  );
}



export function useImages() {
  const context = useContext(Images);
  if (context === undefined) {
    throw new Error("Images.Context must be used within the Images.Provider");
  }
  return context;
}