export const PRODUCTS = [
  { value: "DN", label: ".NET" },
  { value: "AS", label: "AI Assistant" },
  { value: "IH", label: "Innovation Hub" },
  { value: "AMP", label: "Amper" },
  { value: "AQ", label: "Aqua" },
  { value: "CL", label: "CLion" },
  { value: "CLN", label: "CLion Nova" },
  { value: "CPPT", label: "C++ Tools" },
  { value: "CWM", label: "Code With Me" },
  { value: "CC", label: "CodeCanvas" },
  { value: "CMP", label: "Compose Multiplatform" },
  { value: "DG", label: "DataGrip" },
  { value: "DL", label: "Datalore" },
    { value: "DS", label: "DataSpell" },
    { value: "DC", label: "dotCover" },
    { value: "DM", label: "dotMemory" },
    { value: "DP", label: "dotPeek" },
    { value: "DT", label: "dotTrace" },
  { value: "FL", label: "Fleet" },
  { value: "GDV", label: "GameDev" },
  { value: "GW", label: "Gateway" },
  { value: "GO", label: "GoLand" },
  { value: "GZ", label: "Grazie" },
  { value: "HB", label: "Hub" },
  { value: "IS", label: "IDE Services" },
  { value: "IJ", label: "IntelliJ IDEA" },
  { value: "JB", label: "JetBrains" },
  { value: "EDU", label: "JetBrains Academy" },
  { value: "JBAI", label: "JetBrains AI" },
  { value: "JBIDEs", label: "JetBrains IDEs" },
  { value: "KT", label: "Kotlin" },
  { value: "KTR", label: "Ktor" },
  { value: "MP", label: "Marketplace" },
  { value: "MPS", label: "MPS" },
  { value: "PS", label: "PhpStorm" },
    { value: "PC", label: "PyCharm" },
    { value: "QD", label: "Qodana" },
  // { value: "RF", label: "RiderFlow" },
  { value: "RS", label: "ReSharper" },
  { value: "RScpp", label: "ReSharper C++" },
  // { value: "SPC", label: "SpaceCode" },
    { value: "RD", label: "Rider" },
    { value: "RM", label: "RubyMine" },
    { value: "RR", label: "RustRover" },
    { value: "ST", label: "Startup Program" },
    { value: "TC", label: "TeamCity" },
    { value: "TB", label: "Toolbox App" },
  { value: "WS", label: "WebStorm" },
  { value: "WRS", label: "Writerside" },
  { value: "YT", label: "YouTrack" },
];

export const GENERAL_TYPE = {
  label: "General",
  value: "general",
};
export const CHANNELS = [
  {
    label: "Social Media & Blog",
    value: "social",
    visible: true,
    products: ["JB", "GDV", "JBIDEs", "AMP", "DN", "IS", "EDU", "IJ",  "JBAI", "ST", "AS", "AQ", "CL", "CC", "CWM", "DG", "DL", "DC", "DM", "DS", "DT", "GZ", "HB", "MP", "MPS", "KT", "KTR", "GW", "DP", "FL", "GO", "PC", "PS", "QD", "RM", "RR", "RS", "RD", "TC", "TB", "RScpp", "WS", "CMP", "WRS", "YT"],
    layoutsNumber: 7,
    types: [
      {
        label: "General",
        value: "general",
        products: ["JB", "GDV", "JBIDEs", "AMP", "CPPT", "DN", "IS", "EDU", "IJ",  "JBAI", "ST",  "AS", "AQ", "CL", "CWM", "DG", "DL", "DC", "DM", "DS", "DT", "GZ", "HB", "MP", "MPS", "KT", "KTR", "GW", "DP", "FL", "GO", "PC", "PS", "QD", "RM", "RR", "RS", "RD", "TC", "TB", "RScpp", "WS", "CMP", "WRS", "YT"]
      },
      {
        label: "Newsletter",
        value: "newsletter",
        products: ["DN", "JB", "MP", "TC"]
      },
      {
        label: "Java Annotated",
        value: "java-annotated",
        products: ["IJ"]
      },
      {
        label: "Release",
        value: "release",
        products: ["IJ", "AMP", "CLN", "EDU", "RF"]
      },
      {
        label: "EAP",
        value: "eap",
        products: ["IJ", "CLN", "RF"]
      },
      {
        label: "Bug-fix",
        value: "bug-fix",
        products: ["CLN", "RF"]
      },
      {
        label: "Roadmap",
        value: "roadmap",
        products: ["CLN", "CPPT", "EDU", "RF"]
      },
      {
        label: "Workshop",
        value: "workshop",
        products: ["IH"]
      },
      {
        label: "Hackathon",
        value: "hackathon",
        products: ["IH"]
      },
      {
        label: "Webinar",
        value: "webinar",
        products: ["IH"]
      },
      {
        label: "Jam Event",
        value: "jam-event",
        products: ["IH"]
      },
      {
        label: "Tips&Tricks",
        value: "tips-tricks",
        products: ["CPPT", "RF"]
      },
      {
        label: "Events",
        value: "events",
        products: ["CPPT"]
      },
      {
        label: "Interviews",
        value: "interviews",
        products: ["CPPT", "EDU"]
      },
      {
        label: "News",
        value: "news",
        products: ["EDU"]
      },
      {
        label: "JB Academy",
        value: "jb-academy",
        products: ["EDU"]
      },
      {
        label: "Edu IDEs",
        value: "edu-ides",
        products: ["EDU"]
      },
      {
        label: "Learning Courses",
        value: "learning-courses",
        products: ["EDU"]
      },
      {
        label: "Tools for Teaching",
        value: "tools-for-teaching",
        products: ["EDU"]
      },
      {
        label: "PHP Annotated",
        value: "php-annotated",
        products: ["PS"]
      }
    ]
  },
  {
    label: "Internal",
    value: "internal",
    visible: true,
    products: ["IH"],
    layoutsNumber: 6,
    types: [
      {
        label: "Workshop",
        value: "workshop",
        products: ["IH"],
      },
      {
        label: "Hackathon",
        value: "hackathon",
        products: ["IH"],
      },
      {
        label: "Webinar",
        value: "webinar",
        products: ["IH"],
      },
      {
        label: "Jam Event",
        value: "jam-event",
        products: ["IH"],
      }
    ],
  },
  {
    label: "Livestream",
    value: "livestream",
    visible: true,
    types: [],
    products: ["AQ", "CL", "DG", "DS", "EDU", "GO", "IJ", "PC", "PS", "RD", "RM", "RR", "RS", "WS"],
    layoutsNumber: 0,
  },
  {
    label: "Email",
    value: "email",
    visible: true,
    types: [],
    products: ["AMP", "GDV", "AQ", "AS", "CL", "CMP", "CWM", "DC", "DG", "DL", "DM", "DN", "DP", "DS", "DT", "EDU", "FL", "GO", "GW", "GZ", "HB", "IJ", "IS", "JB", "JBAI", "JBIDEs", "KT", "KTR", "MP", "MPS", "PC", "PS", "QD", "RD", "RM", "RR", "RS", "RScpp", "ST", "TB", "TC", "WRS", "WS", "YT"],
    layoutsNumber: 4,
    layoutsConfig: {
      "1": ["Email01", "Email02", "Email03", "Email04"],
      "2": ["Email01"],
      "3": ["Email02", "Email03"],
      "4": ["Email03"],
    }
  },
];

export const findChannelObj = id => CHANNELS.find(ch => id === ch.value)
